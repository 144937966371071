<style scoped>
/* .equipment {
  margin-top: 120px;
} */
.equipment >>> .el-card__header {
  border: none;
}
.equipment .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.equipment .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  top: 50px;
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
  margin-top: 60px;
}
.card-content {
  width: 1116px;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-around;
}
.equipment .content {
  padding: 0 65px;
  box-sizing: border-box;
}
.equipment .content .search-box {
  display: flex;
  justify-content: space-between;
}
.equipment .content .content-box {
  margin-top: 20px;
}
.equipment .content .content-box .title {
  display: block;
  font-size: 18px;
  color: #333333;
  text-align: left;
  line-height: 25px;
  margin-bottom: 10px;
  font-weight: 600;
}
.equipment .content .content-box .item-box {
  display: flex;
  width: 100%;
}
.equipment .content .content-box .item-box .item {
  width: 160px;
  height: 120px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #cccccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  box-sizing: border-box;
  margin-right: 20px;
  cursor: pointer;
}
.equipment .content .content-box .item-box .item .img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;
}
.card-bottom {
  margin-top: 50px;
}
</style>
<template>
  <div class="equipment" id="container">
    <el-card>
      <div slot="header">
        <el-button
          class="back-btn iconfont icon-fanhui-"
          type="text"
          @click="backHome"
        >
          返回首页
        </el-button>
        <p class="card-title">我的设备</p>
      </div>
      <div class="content">
        <div class="search-box">
          <date-picker
            type="年"
            :dateValue="from_time"
            @dateChange="dateChange"
            @typeChange="typeChange"
          ></date-picker>
          <el-button
            style="width: 200px; text-align: left"
            icon="el-icon-search"
            size="small"
            @click.native="toSearchReport"
          >
            搜索报告
          </el-button>
        </div>
        <div class="content-box" v-for="item in list" :key="item.id">
          <span class="title">设备编号:{{ item.deviceId }}</span>
          <div class="item-box">
            <div
              class="item"
              v-for="operation in item.children"
              :key="operation.id"
              @click="toInfo(operation, item.deviceId)"
            >
              <!-- 没有头像地址url -->
              <el-image class="img" :src="url" fit="contain"></el-image>
              <p class="name">操作员：{{ operation.operationName }}</p>
            </div>
          </div>
        </div>
        <el-empty
          v-if="isShow"
          :image="emptyImg"
          :image-size="400"
          style="margin-bottom: 20px"
        >
          <template slot="description">
            <span style="color: #999"> 没有查询到相应内容 </span>
          </template>
        </el-empty>
      </div>
      <div class="card-bottom">
        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="2"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ownerDevice } from "@/api/base";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
export default {
  name: "equipment",
  components: {
    DatePicker,
  },
  data() {
    return {
      isShow: false,
      emptyImg: require("@/assets/images/empty.png"),
      url: require("@/assets/images/profile.png"),
      total: null,
      current: null,
      time_type: "3",
      from_time: null,
      to_time: null,
      list: [], //分页后展示的数据
      dataList: [], //原始获取的数据
    };
  },
  created() {
    this.from_time = String(new Date().getFullYear());
    this.to_time = String(new Date().getFullYear());
    this.getList();
  },
  methods: {
    //前端分页  list是需要分页的数据  n是传入第几页 从第一页开始  根据传入的页码 返回需要展示的数组
    handleCurrentChange(page) {
      let data = this.dataList;
      this.list = this.pagination(data, page);
    },
    //2为每页的size 也可以改为动态传参
    pagination(list, n) {
      let start = 2 * (n - 1);
      let end = 2 * (n - 1) + 2;
      return list.slice(start, end);
    },
    //获取设备信息
    async getList(page = 1) {
      this.list=[]
      this.total = 0
      this.current = page;
      let { time_type, from_time, to_time } = this;
      let res = await ownerDevice({
        time_type,
        from_time,
        to_time,
      });
      if (res.code != 1) return this.$message.error(res.message);
      
      this.dataList = res.data? res.data.records : [];
      if (this.dataList.length == 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.list = this.pagination(this.dataList, page);
        this.total = this.dataList.length;
      }
    },
    //跳转设备详情页
    toInfo(operation, deviceId) {
      let operation_phone = operation.operationPhone;
      let operation_name = operation.operationName;
      this.$router.push({
        path: "/equipmentInfo",
        query: {
          operation,
          deviceId,
          operation_phone,
          operation_name,
          time_type: this.time_type,
          from_time: this.from_time,
          to_time: this.to_time,
        },
      });
    },
    //跳转报告搜索
    toSearchReport() {
        //带operation_phone
        let operation_phone = ''
        let owner_phone = sessionStorage.getItem('phoneNumber')

      this.$router.push({path:"/searchReport",query:{
          owner_phone,
          operation_phone
      }});
    },
    //时间类型选择
    typeChange(e) {
      this.time_type = e;
    },
    //时间选择器返回值 e.dateValue  e.selectValue
    dateChange(e) {
      if (!this.time_type) {
        this.time_type = "3";
      }
      this.from_time = e.from_time;
      this.to_time = e.to_time;
      this.getList();
    },
    //返回首页
    backHome() {
      this.$router.back();
    },
  },
};
</script>
